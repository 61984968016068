import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@material-ui/core"
import { Check } from "@material-ui/icons"
import { PageProps, graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"

import { ContactForm } from "../components/ContactForm"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BgParallax } from "../components/UI/BgParallax"
import Bragger from "../components/UI/Bragger"
import { Container } from "../components/UI/Container"
import { Particles } from "../components/UI/Particles"
import TextSwitcher from "../components/UI/TextSwitcher"
import { useIsLargeScreen } from "../hooks/useIsLargeScreen"
import useIsMediumScreen from "../hooks/useIsMediumScreen"

const IndexPage = ( props: PageProps ) => {
  const { navigate, location, data } = props;

  const isMediumScreen = useIsMediumScreen();
  const isLargeScreen = useIsLargeScreen();
  const [ contactFormIsOpen, setContactFormIsOpen ] = useState( false );
  const contactFormRef = useRef<HTMLFormElement>();

  const theme = useTheme();

  const particleForegroundStyles: React.CSSProperties = {
    pointerEvents: 'none',
    background: `#fcfdfc${ isLargeScreen ? '90' : 'c4' }`,
    backdropFilter: 'blur(0.6px)',
  };

  useEffect( () => {
    if ( location.hash === '#contact' ) {
      setContactFormIsOpen( true );
      (contactFormRef.current?.elements[0] as HTMLInputElement)?.focus();
    }

    if ( (location.state as any)?.noScroll === true )
      return;

    const el = document.getElementById( location.hash.slice(1) );
    if ( el )
      window.scrollTo( { top: el.offsetTop, behavior: 'smooth' } ); 
  }, [ location ] );

  return (
    <Layout>
      <SEO
        title="Home"
        description="We build secure web and mobile applications from the ground up with modern frameworks and DevOps best practices."
      />

      <Particles height="75vh" maxHeight={680} />
      <Box height="75vh" maxHeight={680} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={2} p={1} style={ particleForegroundStyles }>
          <Typography variant="h2" style={{ fontSize: '1.7rem', fontWeight: 300, }}>The Resolute Web Advantage</Typography>
        </Box>
        <Box mb={4} p={1} fontSize="3rem" style={ particleForegroundStyles }>
          <Typography variant="h4" style={{ fontSize: '2.6rem' }} align="center"><TextSwitcher texts={ headings } time={ 3000 } /></Typography>
        </Box>
        <Box mb={12}>
          <Button color="primary" variant="contained" onClick={ () => navigate( '/#contact', { replace: true } ) }>Get Started</Button>
        </Box>
      </Box>

      <BgParallax style={{ marginTop: -50 }} src={ data.background.childImageSharp.fixed.src }>
        Bring your vision to life
      </BgParallax>

      <Container mt={isLargeScreen ? 4 : 1} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" px={4}>
        <Box flex={ '1 1 35%' } textAlign="center" py={4} px={4}>
          <Typography variant="h3" style={{ fontSize: isLargeScreen ? '3rem' : '2rem' }}>THE RESOLUTE WAY</Typography>
        </Box>
        <Box flex={'1 1 62%'} px={4} py={isMediumScreen ? 4 : 0 }>
          <Typography style={{ fontSize: isLargeScreen ? '1.1em' : '0.9em' }}>
            <span style={{ fontWeight: 'bold' }}>Can build anything.</span>
{
  /*
            <p>
              With our years of experience we will build it faster and better than 
              Our technology experts use collaboration tools, cloud services,
              automated testing and continuous deployment technologies to build cloud applications and geospatial solutions.
            </p>
  */
}

            <p>
              Our expert developers, cloud architects, and cost-optimization consultants bring a wealth of experience,
              whether you need consulting for an existing project or a new project to be developed entirely from kickoff to completion.
            </p>

            <p>
              We build secure web and mobile applications from the ground up with modern frameworks and DevOps best practices.
              Our applications scale automatically, are easy to update, and exceed your expectations in
                aesthetics, usability, performance, and reliability.
            </p>

{
  // so you can affordably add new features and stay competitive
}
          </Typography>
        </Box>
      </Container>

      <Container>
        <Box mt={isLargeScreen ? 4 : 2} display="flex" flexWrap="wrap-reverse" justifyContent="center" alignItems="center" px={4}>
          <Box flex={'1 1 62%'} px={4} py={isLargeScreen ? 2 : 0}>
            <Typography style={{ fontSize: isLargeScreen ? '1.1em' : '0.9em' }}>
              <span style={{ fontWeight: 'bold' }}>Starting a new project?</span>

{
  // augment your vision
  // "manifest requirements"
}
              
              <p>
                Cultivate your ideas with us and make them flourish.
              </p>

              <p>
                We explore the details of your vision, align with your goals, and
                suggest novel insights for your consideration. Then we provide you
                a design spec and a concrete roadmap to bring your
                project from concept to reality.  
              </p>
            </Typography>
          </Box>
          <Box flex={ '1 1 35%' } textAlign="center" py={4} px={4}>
            <Typography variant="h3" style={{ fontSize: isLargeScreen ? '3rem' : '2rem' }}>GROW YOUR IDEAS</Typography>
          </Box>
        </Box>
      </Container>

      <Container
        mt={isLargeScreen ? 8 : 6}
        mb={isLargeScreen ? 8 : 2}
        px={8}
      >
        <Bragger />
      </Container>

      <Container>
        <Box mb={8} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" px={4}>
          <Box flex={ '1 1 35%' } textAlign="center" py={4} px={4}>
            <Typography variant="h3" style={{ fontSize: isLargeScreen ? '3rem' : '2rem' }}>CONTINUOUS COLLABORATION</Typography>
          </Box>
          <Box flex={'1 1 62%'} px={4} py={isLargeScreen ? 2 : 0}>
            <Typography style={{ fontSize: isLargeScreen ? '1.1em' : '0.9em' }}>
              <span style={{ fontWeight: 'bold' }}>Designed precisely your way.</span>
              <p>Collaborate with us on user experience design. See the results of your adjustments to styles, interactions, and animations, right before your eyes in live design sessions.</p>    

              <p>
                We build reusable and accessible components and use continuous integration and deployment, so
                you will always have direct access to the latest changes and see daily progress while your project is being built.
              </p>
            </Typography>
          </Box>
        </Box>
      </Container>

{
  /*
}
      <Container mb={2}>
        <Box mt={2} display="flex" flexWrap="wrap-reverse" justifyContent="center" alignItems="center" px={4}>
          <Box flex={'1 1 62%'} px={4} py={isLargeScreen ? 2 : 0}>
            <Typography style={{ fontSize: isLargeScreen ? '1.1em' : '0.9em' }}>
              <span style={{ fontWeight: 'bold' }}>Scalable, secure, and optimized cloud-native applications. </span>

              <p>
                Our AWS certified developers build applications that scale automatically and cost-effectively,
                using the latest technology and strategies to minimize costs and maximize performance.
              </p>
            </Typography>
          </Box>
          <Box flex={ '1 1 35%' } textAlign="center" py={4} px={4}>
            <Typography variant="h3" style={{ fontSize: isLargeScreen ? '3rem' : '2rem' }}>OUR APPROACH</Typography>
          </Box>
        </Box>
      </Container>
*/
}
{
  /*
      <Container>
        <Box mt={2} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" px={4}>
          <Box flex={ '1 1 35%' } textAlign="center" py={4} px={4}>
            <Typography variant="h3" style={{ fontSize: isLargeScreen ? '3rem' : '2rem' }}>APPLICATIONS THAT SCALE</Typography>
          </Box>
          <Box flex={'1 1 62%'} px={4}>
            <Typography style={{ fontSize: isLargeScreen ? '1.1em' : '0.9em' }}>
              <span style={{ fontWeight: 'bold' }}>Scalable, secure, and optimized cloud-native applications. </span>
              Our AWS certified developers build applications that scale automatically and cost-effectively, using the latest technology and strategies to minimize costs and maximize performance.
            </Typography>
          </Box>
        </Box>
      </Container>
  */
}

      <BgParallax src={ data.buildings.childImageSharp.fixed.src }>
        Let's start a project
      </BgParallax>

      <Container>
        <Box mt={2} display="flex" flexWrap="wrap-reverse" justifyContent="center" alignItems="center" px={4}>
          <Box flex={'1 1 61%'} px={isLargeScreen ? 24 : 6}>
            <Typography style={{ fontSize: isLargeScreen ? '1.1em' : '0.9em' }}>
              <p style={{ margin: 0 }}>We also offer hourly consulting and training for the following subject areas: </p>

              <List>
                <ListItem>
                  <ListItemIcon style={{ color: theme.palette.primary.main }}><Check style={{ fontSize: '1.7rem' }} /></ListItemIcon>
                  <ListItemText>AWS (all services)</ListItemText>
                </ListItem>
                <ListItem>
                 <ListItemIcon style={{ color: theme.palette.primary.main }}><Check style={{ fontSize: '1.7rem' }} /></ListItemIcon>
                  <ListItemText>Domain-Driven Design</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ color: theme.palette.primary.main }}><Check style={{ fontSize: '1.7rem' }} /></ListItemIcon>
                  <ListItemText>Microservices</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ color: theme.palette.primary.main }}><Check style={{ fontSize: '1.7rem' }} /></ListItemIcon>
                  <ListItemText>React / Next.js / Gatsby</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ color: theme.palette.primary.main }}><Check style={{ fontSize: '1.7rem' }} /></ListItemIcon>
                  <ListItemText>Node.js / NestJS</ListItemText>
                </ListItem>
              </List>
            </Typography>
          </Box>
          <Box flex={ '1 1 35%' } textAlign="center" py={4} px={4}>
            <Typography variant="h3" style={{ fontSize: isLargeScreen ? '3rem' : '2rem' }}>CONSULTING AND TRAINING</Typography>
          </Box>
        </Box>
      </Container>

{
   /*
      <Container>
        <Box id="services" px={4} display="flex" justifyContent="space-between" alignItems="stretch" flexWrap="wrap">
          <Box m={4} flex={'1 1 25%'} flexDirection="column" alignItems="center" textAlign="center">
            <Card elevation={2} style={{ width: '100%', height: '100%' }}>
              <Typography variant="h4">Development</Typography>
              <ul style={{ display: 'block', margin: 'auto' }}>
                <li>React</li>
                <li>Nest.js</li>
                <li>TypeScript</li>
              </ul>
              <Box p={1} display="flex" justifyContent="center" alignItems="center">
                <img style={{ width: 32 }} src={ typeScriptLogo } alt="TypeScript" />
                <img style={{ width: 32 }} src={ nestJsLogo } alt="NestJS" />
                <img style={{ width: 32 }} src={ reactLogo } alt="React" />
                <img style={{ width: 32 }} src={ webpackLogo } alt="webpack" />
              </Box>
              - PostgreSQL
              - MySQL
              - Sequelize
              - TypeORM
              - JavaScipt / Node.js
              - Docker
              - Kubernetes
              - AWS
              - GCP
              - Vue
              - Angular
              - express
              - Firebase
            </Card>
          </Box>
          <Box m={4} flex={'1 1 25%'} flexDirection="column" alignItems="center" textAlign="center">
            <Card elevation={2} style={{ width: '100%', height: '100%' }}>
              <Typography variant="h4">Consulting</Typography>
              <Typography>Architecture</Typography>
              <Typography>Infrastructure</Typography>
              <Typography>Microservices</Typography>
              <Typography>Database Performance</Typography>
            </Card>
          </Box>
          <Box m={4} flex={'1 1 25%'} flexDirection="column" alignItems="center" textAlign="center">
            <Card elevation={2} style={{ width: '100%', height: '100%' }}>
              <Typography variant="h4">Training</Typography>
              <Typography>React</Typography>
              <Typography>Nest.js</Typography>
              <Typography>AWS</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img style={{ width: 32 }} src={ nestJsLogo } alt="NestJS" />
                <img style={{ width: 32 }} src={ reactLogo } alt="React" />
                <img style={{ width: 32 }} src={ webpackLogo } alt="webpack" />
              </Box>
            </Card>
          </Box>
        </Box>
      </Container>
*/
}
      <Container>
        <Box id="contact" flex={1} px={2} mt={2} display="flex" flexDirection="column" alignItems="center">
          <Box width="100%" maxWidth={480}>
            <ContactForm ref={ contactFormRef } open={ contactFormIsOpen } onOpen={ () => navigate( '/#contact', { replace: true, state: { noScroll: true } } ) } />
          </Box>
        </Box>
      </Container>

      {
        /* 
      }
      <BgParallax src={ data.background.childImageSharp.fixed.src }>
        Latest Blog Posts
      </BgParallax>

      <Box m="auto" maxWidth={ 1140 }>
        <PostCarousal />
      </Box>
      {
        */
      }
    </Layout>
  );
};

export default IndexPage;

const headings = [
  'Data Visualization',
  'Search Engine Optimization',
  'Light-speed Performance',
  'Continuous Integration',
  'Site-Reliability Engineering',
  'End-to-end Testing',
  'Event-driven Systems',
  'Realtime Collaboration',
  'Component-driven Development',
  'Automated Testing',
  'Continuous Deployment',
  'Infrastructure as Code',
];

export const query = graphql`
  query {
    background: file(relativePath: { eq: "night-beach.png" } ) {
      childImageSharp {
        fixed(width: 1920, height: 1080) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    buildings: file(relativePath: { eq: "buildings.jpg" } ) {
      childImageSharp {
        fixed(width: 1920, height: 1080) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;